<template>
  <div class="space-y-4">
    <article>
      <h3 class="subheader">
        Quick Links
      </h3>
      <br />
      <section
        class="relative z-0  rounded-md grid sm:grid-cols-1 md:grid-cols-4"
      >
        <asom-button
        v-if="canCreateOpsCommCirculars"
          variant="primary"
          outline
          rounded="none"
          @click="
            $router.push({
              name: 'Create CP Update',
            })
          "
          icon="calendar-next"
          text="Create CP Update"
        />
        <asom-button
          variant="primary"
          outline
          rounded="none"
          @click="
            $router.push({
              name: 'CP Update Report',
            })
          "
          icon="loadbar-doc"
          text="CP Update Report"
        />
        <asom-button
          variant="primary"
          outline
          rounded="none"
          @click="
            $router.push({
              name: 'Officer Acknowledgement Report',
            })
          "
          icon="loadbar-doc"
          text="Officer Acknowledgement Report"
        />
        <asom-button
          v-if="canEditOpsCommExpireThreshold"
          variant="primary"
          outline
          rounded="none"
          @click="
            $router.push({
              name: 'OPS COMM Configurations Page',
            })
          "
          icon="globe-alt"
          text="Configurations"
        >
        </asom-button>
      </section>
    </article>
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <div class="pt-4">
      <dl class="grid sm:grid-cols-1 md:grid-cols-5 gap-3 pb-6">
        <aside class="rounded-lg bg-white overflow-hidden shadow">
          <div class="space-y-1  p-3">
            <dt class="text-base font-normal text-gray-900">
              Acknowledgements
            </dt>
            <dd class="text-3xl font-semibold text-gray-900 text-left">
              {{ cards.countOfAcknowledgements }}
            </dd>
          </div>
        </aside>
        <aside class="rounded-lg bg-white overflow-hidden shadow">
          <div class="space-y-1  p-3">
            <dt class="text-base font-normal text-gray-900">
              Pending Acknowledgements
            </dt>
            <dd class="text-3xl font-semibold text-gray-900 text-left">
              {{ cards.countOfPendingAcknowledgements }}
            </dd>
          </div>
        </aside>
      </dl>
      <asom-card title="Circular/Procedure Updates">
        <asom-client-table
          v-if="!isLoading"
          :columns="[
            'cpUpdateNo',
            'dateCreated',
            'createdBy',
            'title',
            'acknowledgementDeadlineDate',
            'expiryDate',
            'noOfSentAcknowledgments',
            'noOfAcknowledgments',
            displayUpdateActions() && 'action',
          ]"
          :data="itemList"
        >
          <template v-slot:header_noOfSentAcknowledgments
            >Total Sent Out</template
          >
          <template v-slot:header_noOfAcknowledgments>Acknowledged</template>
          <template v-slot:cpUpdateNo="scopedSlots">
            <button
              @click="navigateToView(scopedSlots.rowData)"
              class="text-button underline font-semibold"
            >
              {{ scopedSlots.data }}
            </button>
          </template>
          <template v-slot:dateCreated="scopedSlots">
            <span>{{ displayUtcDate(scopedSlots.data) }}</span>
          </template>
          <template v-slot:acknowledgementDeadlineDate="scopedSlots">
            <span>{{ displayUtcDate(scopedSlots.data) }}</span>
          </template>
          <template v-slot:expiryDate="scopedSlots">
            <span>{{
              scopedSlots.data ? displayUtcDate(scopedSlots.data) : "NA"
            }}</span>
          </template>
          <template v-slot:action="scopedSlots">
            <div class="flex flex-col gap-1 w-48">
              <asom-button
                size="sm"
                outline
                text="Add Recipients"
                @click="updateClicked(scopedSlots.rowData)"
              />
              <asom-button
                size="sm"
                outline
                text="Update Expiry Date"
                @click="updateExpiryClicked(scopedSlots.rowData)"
              />
              <asom-button
                size="sm"
                outline
                text="Update Deadline Date"
                @click="updateDeadlineClicked(scopedSlots.rowData)"
              />
              <asom-button
                size="sm"
                outline
                text="Remove"
                variant="error"
                @click="removeClicked(scopedSlots.rowData)"
              />
            </div>
          </template>
        </asom-client-table>
        <div v-else class="text-center">
          <asom-icon icon="spinner" class="animate-spin" />
        </div>
      </asom-card>
    </div>
    <asom-modal
      title="Remove CP Update"
      v-model="showRemoveModal"
      :dismissible="false"
    >
      <asom-alert
        variant="error"
        v-if="removeModalError"
        :error-message="removeModalError"
      />
      <p class="pt-4">Are you sure you would like to proceed?</p>
      <div class="flex flex-row-reverse pt-8">
        <div class="pl-4">
          <asom-button
            @click="onRemoveCPUpdate"
            text="Remove"
            :loading="isSubmittingRemove"
          />
        </div>
        <div>
          <asom-button
            @click="toggleRemoveModal(false)"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
    <asom-modal
      title="Add Recipients"
      v-model="showUpdateModal"
      :dismissible="false"
    >
      <asom-alert
        variant="error"
        v-if="updateModalError"
        :error-message="updateModalError"
      />
      <asom-form-field
        required
        label="Individual Recipients"
        :state="inputStates('updateFormData.individualRecipients')"
        error="Individual Recipients required"
      >
        <asom-multi-select
          placeholder="Select Individual Recipients"
          :state="inputStates('updateFormData.individualRecipients')"
          v-model="updateFormData.individualRecipients"
          :loading="isLoadingOfficerOptions"
          :filterResults="false"
          :options="officerOptions"
          @search-change="findOfficers"
        />
      </asom-form-field>
      <asom-form-field
        required
        label="Remarks"
        :state="inputStates('updateFormData.remarks')"
        error="Remarks is required"
      >
        <asom-input-textarea
          type="text"
          v-model="updateFormData.remarks"
          :state="inputStates('updateFormData.remarks')"
          :maxlength="1000"
        />
      </asom-form-field>
      <div class="flex flex-row-reverse pt-8">
        <div class="pl-4">
          <asom-button
            @click="onUpdateCPUpdate"
            text="Update"
            :loading="isSubmittingUpdate"
          />
        </div>
        <div>
          <asom-button
            @click="toggleUpdateModal(false)"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
    <asom-modal
      title="Update Expiry Date"
      v-model="showUpdateExpireModal"
      :dismissible="false"
    >
      <asom-alert
        v-if="updateExpireModalError"
        variant="error"
        :error-message="updateExpireModalError"
      />
      <asom-form-field
        class="col-span-2"
        label="Expiry Date"
        required
        :state="inputStates('updateExpireFormData.expiryDate')"
        error="Expiry Date is required"
      >
        <asom-input-date
          v-model="updateExpireFormData.expiryDate"
          :state="inputStates('updateExpireFormData.expiryDate')"
        />
      </asom-form-field>
      <asom-form-field
        required
        label="Remarks"
        :state="inputStates('updateExpireFormData.remarks')"
        error="Remarks is required"
      >
        <asom-input-textarea
          type="text"
          v-model="updateExpireFormData.remarks"
          :state="inputStates('updateExpireFormData.remarks')"
          :maxlength="1000"
        />
      </asom-form-field>
      <div class="flex flex-row-reverse pt-6">
        <div class="pl-4">
          <asom-button
            @click="onUpdateExpiry"
            text="OK"
            :loading="isSubmittingUpdateExpire"
          />
        </div>
        <div>
          <asom-button
            @click="toggleUpdateExpiryModal(false)"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
    <asom-modal
      title="Update Deadline Date"
      v-model="showUpdateDeadlineModal"
      :dismissible="false"
    >
      <asom-alert
        v-if="updateDeadlineModalError"
        variant="error"
        :error-message="updateDeadlineModalError"
      />
      <asom-form-field
        class="col-span-2"
        label="Deadline Date"
        required
        :state="inputStates('updateDeadlineFormData.deadline')"
        error="Deadline Date is required"
      >
        <asom-input-date
          v-model="updateDeadlineFormData.deadline"
          :state="inputStates('updateDeadlineFormData.deadline')"
        />
      </asom-form-field>
      <asom-form-field
        required
        label="Remarks"
        :state="inputStates('updateDeadlineFormData.remarks')"
        error="Remarks is required"
      >
        <asom-input-textarea
          type="text"
          v-model="updateDeadlineFormData.remarks"
          :state="inputStates('updateDeadlineFormData.remarks')"
          :maxlength="1000"
        />
      </asom-form-field>
      <div class="flex flex-row-reverse pt-6">
        <div class="pl-4">
          <asom-button
            @click="onUpdateDeadline"
            text="OK"
            :loading="isSubmittingUpdateDeadline"
          />
        </div>
        <div>
          <asom-button
            @click="toggleUpdateDeadlineModal(false)"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
  </div>
</template>

<script>
import get from "lodash.get";
import { mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import inputStates from "@/mixins/inputStates";
import { required } from "@vuelidate/validators";
import { displayUtcDate } from "@/helpers/dateTimeHelpers";
import {
  removeCPUpdate,
  getCPUpdateSummary,
  editCPUpdate,
} from "../../../services/opsComms.service";
import { listUsers } from "@/services/user.service";
import ROLES from "../../../constants/APIEnums/roles";

export default {
  setup: () => ({ v$: useVuelidate() }),
  mixins: [inputStates],
  data() {
    return {
      isLoading: false,
      itemList: [],
      cards: {
        countOfAcknowledgements: "-",
        countOfPendingAcknowledgements: "-",
      },
      error: null,
      showRemoveModal: false,
      isSubmittingRemove: false,
      removeModalError: null,
      selectedItem: null,
      officerOptions: [],
      isLoadingOfficerOptions: false,
      updateFormData: {
        individualRecipients: null,
        remarks: null,
      },
      showUpdateModal: false,
      isSubmittingUpdate: false,
      updateModalError: null,
      updateExpireFormData: {
        expiryDate: null,
        remarks: null,
      },
      showUpdateExpireModal: false,
      isSubmittingUpdateExpire: false,
      updateDeadlineModalError: null,
      updateDeadlineFormData: {
        deadline: null,
        remarks: null,
      },
      showUpdateDeadlineModal: false,
      isSubmittingUpdateDeadline: false,
    };
  },
  mounted() {
    this.getPageData();
  },
  computed: {
    ...mapGetters({
      canEditOpsCommExpireThreshold: "auth/canEditOpsCommExpireThreshold",
      canCreateOpsCommCirculars: "auth/canCreateOpsCommCirculars",
      isMSO: "auth/isMSO",
      isSOM: "auth/isSOM",
      isOpsAdmin: "auth/isOpsAdmin",
      isSysAdmin: "auth/isSysAdmin"
    }),
  },
  validations() {
    return {
      updateFormData: {
        individualRecipients: { required },
        remarks: { required },
      },
      updateExpireFormData: {
        expiryDate: { required },
        remarks: { required },
      },
      updateDeadlineFormData: {
        deadline: { required },
        remarks: { required },
      },
    };
  },
  methods: {
    get,
    displayUtcDate,
    async getPageData() {
      this.isLoading = true;
      const resp = await getCPUpdateSummary({
        StationId: this.userStationId,
      });
      if (resp.success) {
        this.itemList = get(resp.payload, "list", []);
        this.cards.countOfAcknowledgements = get(
          resp.payload,
          "countOfAcknowledgements",
          "-"
        );
        this.cards.countOfPendingAcknowledgements = get(
          resp.payload,
          "countOfPendingAcknowledgements",
          "-"
        );
        this.isLoading = false;
        this.error = null;
      } else {
        this.isLoading = false;
        this.error = resp.payload;
        this.$scrollTop();
        return;
      }
    },
    navigateToView(selectedItem) {
      this.$router.push({
        name: "View CP Update",
        params: selectedItem,
      });
    },
    removeClicked(selectedItem) {
      this.selectedItem = selectedItem;
      this.toggleRemoveModal(true);
    },
    toggleRemoveModal(value) {
      this.removeModalError = null;
      this.showRemoveModal = value;
    },
    async onRemoveCPUpdate() {
      this.isSubmittingRemove = true;
      this.removeModalError = null;
      const result = await removeCPUpdate({
        id: get(this.selectedItem, "cpUpdateId"),
      });
      if (result.success) {
        this.isSubmittingRemove = false;
        this.toggleRemoveModal(false);
        this.getPageData();
      } else {
        this.isSubmittingRemove = false;
        this.removeModalError = result.payload;
        this.toggleRemoveModal(false);
        this.$scrollTop();
      }
    },
    async findOfficers(query) {
      this.error = null;
      this.isLoadingOfficerOptions = true;
      if (!query) {
        this.isLoadingOfficerOptions = false;
        this.officerOptions = [];
      }
      const result = await listUsers({
        skip: 0,
        take: 100,
        search: query.trim(),
      });
      if (result.success && Array.isArray(get(result, "payload.list"))) {
        this.isLoadingOfficerOptions = false;
        this.error = null;
        const validRoles = [
            ROLES.STATION_MANAGER.name,
            ROLES.ASSISTANT_STATION_MANAGER.name,
            ROLES.SENIOR_STATION_MANAGER.name,
            ROLES.SENIOR_ASSISTANT_STATION_MANAGER.name,
            ROLES.STATION_MANAGER_CONTROL_ROOM.name,
          ];
        this.officerOptions = get(result, "payload.list", [])
        .filter(({roles}) => { return roles.filter(role => validRoles.includes(role)).length })
        .map(
          ({ id, name }) => ({
            label: name,
            value: id,
          })
        );
      } else {
        this.isLoadingOfficerOptions = false;
        this.error = result.payload;
        this.officerOptions = [];
      }
    },
    updateClicked(selectedItem) {
      this.selectedItem = selectedItem;
      this.toggleUpdateModal(true);
    },
    toggleUpdateModal(value) {
      this.updateModalError = null;
      this.showUpdateModal = value;
    },
    async onUpdateCPUpdate() {
      this.updateModalError = null;
      this.v$.updateFormData.$reset();
      this.v$.updateFormData.$touch();
      if (!this.v$.updateFormData.$invalid) {
        let individualRecipientsIds = [];
        if (this.updateFormData.individualRecipients) {
          individualRecipientsIds = this.updateFormData.individualRecipients.map(
            (individualRecipient) => individualRecipient.value
          );
        }
        this.isSubmittingUpdate = true;
        let payload = {
          id: get(this.selectedItem, "cpUpdateId", null),
          remarks: this.updateFormData.remarks,
          recipients: individualRecipientsIds,
          deadlineDate: get(
            this.selectedItem,
            "acknowledgementDeadlineDate",
            null
          ),
        };
        if (get(this.selectedItem, "expiryDate", null) !== null)
          payload.expiryDate = get(this.selectedItem, "expiryDate");

        const result = await editCPUpdate(payload);
        if (result.success) {
          this.isSubmittingUpdate = false;
          this.toggleUpdateModal(false);
          this.getPageData();
        } else {
          this.isSubmittingUpdate = false;
          this.updateModalError = result.payload;
          this.toggleUpdateModal(false);
          this.$scrollTop();
        }
      } else {
        this.updateModalError = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    updateExpiryClicked(selectedItem) {
      this.selectedItem = selectedItem;
      this.updateExpireFormData.expiryDate = get(
        selectedItem,
        "expiryDate",
        null
      );
      this.toggleUpdateExpiryModal(true);
    },
    toggleUpdateExpiryModal(value) {
      this.updateExpireModalError = null;
      this.showUpdateExpireModal = value;
    },
    async onUpdateExpiry() {
      this.updateExpireModalError = null;
      this.v$.updateExpireFormData.$reset();
      this.v$.updateExpireFormData.$touch();
      if (!this.v$.updateExpireFormData.$invalid) {
        this.isSubmittingUpdateExpire = true;
        const result = await editCPUpdate({
          id: get(this.selectedItem, "cpUpdateId"),
          remarks: this.updateExpireFormData.remarks,
          expiryDate: this.updateExpireFormData.expiryDate,
          recipients: [],
          deadlineDate: get(
            this.selectedItem,
            "acknowledgementDeadlineDate",
            null
          ),
        });
        if (result.success) {
          this.isSubmittingUpdateExpire = false;
          this.toggleUpdateExpiryModal(false);
          this.getPageData();
        } else {
          this.isSubmittingUpdateExpire = false;
          this.updateExpireModalError = result.payload;
          this.toggleUpdateExpiryModal(false);
          this.$scrollTop();
        }
      } else {
        this.updateExpireModalError = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    updateDeadlineClicked(selectedItem) {
      this.selectedItem = selectedItem;
      this.updateDeadlineFormData.deadline = get(
        selectedItem,
        "acknowledgementDeadlineDate",
        null
      );
      this.toggleUpdateDeadlineModal(true);
    },
    toggleUpdateDeadlineModal(value) {
      this.updateDeadlineModalError = null;
      this.showUpdateDeadlineModal = value;
    },
    async onUpdateDeadline() {
      this.updateDeadlineModalError = null;
      this.v$.updateDeadlineFormData.$reset();
      this.v$.updateDeadlineFormData.$touch();
      if (!this.v$.updateDeadlineFormData.$invalid) {
        this.isSubmittingUpdateDeadline = true;
        const result = await editCPUpdate({
          id: get(this.selectedItem, "cpUpdateId"),
          remarks: this.updateDeadlineFormData.remarks,
          deadlineDate: this.updateDeadlineFormData.deadline,
          recipients: [],
          expiryDate: get(this.selectedItem, "expiryDate", null),
        });
        if (result.success) {
          this.isSubmittingUpdateDeadline = false;
          this.toggleUpdateDeadlineModal(false);
          this.getPageData();
        } else {
          this.isSubmittingUpdateDeadline = false;
          this.updateDeadlineModalError = result.payload;
          this.toggleUpdateDeadlineModal(false);
          this.$scrollTop();
        }
      } else {
        this.updateDeadlineModalError = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    displayUpdateActions(){
      return this.isMSO || this.isOpsAdmin || this.isSysAdmin;
    },
  },
};
</script>
